<template>
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <!--
    <div class="mb-1">
      <b-card-code>
        <app-collapse
          v-for="(date, dateIndex) in congressesActivities"
          :key="dateIndex"
          accordion
        >
          <app-collapse-item
            :title="moment(dateIndex, 'YYYYMMDD').format('LL')"
            class="border"
          >
            <div
              v-for="(room, roomIndex) in Object.keys(date)"
              :key="roomIndex"
            >
              <div class="row">
                <b-col
                  v-for="(event, eventIndex) in congressesActivities[
                    dateIndex
                  ][room]"
                  :key="eventIndex"
                  cols="12"
                  md="4"
                  sm="12"
                >
                  <b-card
                    no-body
                    class="card-developer-meetup"
                  >
                    <div class="bg-light-primary rounded-top text-center">
                      <b-img
                        :src="require('@/assets/images/illustration/email.svg')"
                        alt="Meeting Pic"
                        height="170"
                      />
                    </div>
                    <b-card-body>
                      -->
    <!-- metting header -->
    <!--
    <div class="meetup-header d-flex align-items-center">
      <div class="meetup-day">
        <h6 class="mb-0">
          {{ (moment(dateIndex, "YYYYMMDD").format('ddd')).toUpperCase() }}
        </h6>
        <h3 class="mb-0">
          {{ moment(dateIndex, "YYYYMMDD").format('D') }}
        </h3>
      </div>
      <div class="my-auto">
        <b-card-title class="mb-25">
          {{ event.title }}
        </b-card-title>
        <b-card-text class="mb-0">
          Sala {{ event.room }}
        </b-card-text>
      </div>
    </div>
    -->
    <!-- metting header -->

    <!-- media -->
    <!--
    <b-media
      no-body
    >
      <b-media-aside
        class="mr-1"
      >
        <b-avatar
          rounded
          variant="light-primary"
          size="34"
        >
          <feather-icon
            icon="CalendarIcon"
            size="18"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h6 class="mb-0">
          {{ moment(dateIndex, "YYYYMMDD").format('LL') }}
        </h6>
        <small> Hórario: {{ moment(event.start_time, 'h:mm').format('LT') }} - {{ moment(event.end_time, 'h:mm').format('LT') }}</small>
      </b-media-body>
    </b-media>
    -->
    <!-- avatar group -->
    <!--
    <b-avatar-group
      class="mt-2 pt-50"
      size="33"
    >
      <b-avatar
        v-for="speaker in event.speakers"
        :key="speaker.id"
        v-b-tooltip.hover.bottom="speaker.name_badge"
        :src="`https://apisnno.ovnicloud.page/storage/image/${speaker.photo}`"
        class="pull-up"
      />
    </b-avatar-group>
    <br>
    <b-button
      v-b-modal="'modal-'+event.id"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
    >
      Detalhes
    </b-button>
    <b-button
      v-b-modal="'agendar-'+event.id"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      style="margin: 5px"
      variant="outline-primary"
    >
      Agendar
    </b-button>
    <b-button
      v-if="
        isOnTime(
          dateIndex,
          event.start_time,
          event.end_time
        )
      "
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      style="margin: 5px"
      variant="outline-success"
      :href="'/congress/'+form.slug+'/'+event.slug"
      block
    >
      Assistir
    </b-button>
    <b-modal
      :id="`agendar-${event.id}`"
      title="Agendar"
      hide-footer
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mt-3"
        variant="outline-primary"
        style="margin: 3px"
        :href="
          URLGenerateGoogleCalendar(
            event.title.replace(
              /\s+/g,
              '%20'
            ),
            event.date.substring(4, 0),
            event.date.substring(7, 5),
            event.date.substring(10, 8),
            parseInt(
              event.start_time.substring(2, 0)
            ) + 3,
            event.date.substring(4, 0),
            event.date.substring(7, 5),
            event.date.substring(10, 8),
            parseInt(
              event.end_time.substring(2, 0)
            ) + 3,
            event.title.replace(/\s+/g, '%20')
          )
        "
        target="_blank"
      >
        Google agenda
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mt-3"
        variant="outline-primary"
        style="margin: 3px"
        @click="ICALGenerate(event)"
      >
        Ical
      </b-button>
      <b-button
        v-if="userEvents.some(e => e.event_id == event.id) === false"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="mt-3"
        variant="outline-primary"
        style="margin: 3px"
        @click="ActiveNotification(event.id)"
      >
        Ativar notificação
      </b-button>
      <b-button
        v-else
        class="mt-3"
        variant="danger"
        style="margin: 3px"
        @click="DeactiveNotification(event.id)"
      >
        Desativar notificação
      </b-button>
      <b-button
        class="mt-3"
        variant="danger"
        block
        @click="$bvModal.hide('agendar-'+event.id)"
      >
        Fechar
      </b-button>
    </b-modal>

    <b-modal
      :id="'modal-'+event.id"
      :title="event.title"
      hide-footer
    >
      <div>
        <p v-if="event.cordinator != null">
          <b>
            Moderador:
          </b>
        </p>
        <p>
          {{ event.cordinator }}
        </p>
      </div>
      <div
        v-for="blocks in event.blocks"
        :key="blocks.id"
      >
        <p>
          <b>
            {{ blocks.title }}
          </b>
        </p>
        <p
          v-for="speaker in blocks.events_blocks_speaker"
          :key="speaker.id"
        >
          {{ speaker.name_badge }}
        </p>
      </div>
      <b-button
        class="mt-3"
        variant="danger"
        block
        @click="$bvModal.hide('modal-'+event.id)"
      >
        Fechar
      </b-button>
    </b-modal>
    </b-card-body>
    </b-card>
    </b-col>
    </div>
    </div>
    </app-collapse-item>
    </app-collapse>

    <template #code>
      {{ codeAccordionDefault }}
    </template>
    </b-card-code>
    </div>
    -->
    <div>
      <b-link
        :href="`/atividades-anteriores/1/`+form.slug"
        variant="primary"
        style="margin-bottom: 5px"
        class="btn btn-primary"
      >
        Aulas passadas
      </b-link>
      <b-link
        :href="'/todas-atividades/1/'+form.slug"
        variant="primary"
        style="margin: 0 5px 5px 5px;"
        class="btn btn-primary"
      >
        Todas as aulas
      </b-link>
      <div
        v-for="(date, dateIndex) in congressesActivities"
        :key="dateIndex"
        class="accordion"
        role="tablist"
      >
        <b-card
          no-body
          class="mb-1"
          style="background-color: transparent !important"
        >
          <b-card-header
            header-tag="header"
            class="p-1"
            role="tab"
            style="background-color: transparent !important"
          >
            <b-button
              v-b-toggle="dateIndex"
              block
              style="border-color: none !important;"
              variant="outline-primary"
            >
              {{ moment(dateIndex, "YYYYMMDD").format('LL') }}
            </b-button>
          </b-card-header>
          <b-collapse
            :id="dateIndex"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <div
              v-for="(room, roomIndex) in Object.keys(date)"
              :key="roomIndex"
            >
              <div class="row">
                <b-col
                  v-for="(event, eventIndex) in congressesActivities[
                    dateIndex
                  ][room]"
                  :key="eventIndex"
                  cols="12"
                  md="4"
                  sm="12"
                >
                  <b-card
                    no-body
                    class="card-developer-meetup"
                  >
                    <div class="bg-light-primary rounded-top text-center">
                      <b-img
                        :src="require('@/assets/images/illustration/thumb_padrao_510x170.svg')"
                        alt="Meeting Pic"
                        height="170"
                        width="100%"
                      />
                    </div>
                    <b-card-body>
                      <!-- metting header -->
                      <div class="meetup-header d-flex align-items-center">
                        <div class="meetup-day">
                          <h6 class="mb-0">
                            {{ (moment(dateIndex, "YYYYMMDD").format('ddd')).toUpperCase() }}
                          </h6>
                          <h3 class="mb-0">
                            {{ moment(dateIndex, "YYYYMMDD").format('D') }}
                          </h3>
                        </div>
                        <div class="my-auto">
                          <b-card-title class="mb-25">
                            {{ event.title }}
                          </b-card-title>
                          <b-card-text class="mb-0">
                            Sala {{ event.room }}
                          </b-card-text>
                        </div>
                      </div>
                      <!--/ metting header -->

                      <!-- media -->
                      <b-media
                        no-body
                      >
                        <b-media-aside
                          class="mr-1"
                        >
                          <b-avatar
                            rounded
                            variant="light-primary"
                            size="34"
                          >
                            <feather-icon
                              icon="CalendarIcon"
                              size="18"
                            />
                          </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                          <h6 class="mb-0">
                            {{ moment(dateIndex, "YYYYMMDD").format('LL') }}
                          </h6>
                          <small> Hórario: {{ moment(event.start_time, 'h:mm').format('LT') }} - {{ moment(event.end_time, 'h:mm').format('LT') }}</small>
                        </b-media-body>
                      </b-media>

                      <!-- avatar group -->
                      <b-avatar-group
                        class="mt-2 pt-50"
                        size="33"
                      >
                        <b-avatar
                          v-for="speaker in event.speakers"
                          :key="speaker.id"
                          v-b-tooltip.hover.bottom="speaker.name_badge"
                          :src="`https://apisnno.ovnicloud.page/storage/image/${speaker.photo}`"
                          class="pull-up"
                        />
                      </b-avatar-group>
                      <br>
                      <b-button
                        v-b-modal="'modal-'+event.id"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                      >
                        Detalhes
                      </b-button>
                      <b-button
                        v-b-modal="'agendar-'+event.id"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        style="margin: 5px"
                        variant="outline-primary"
                      >
                        Agendar
                      </b-button>
                      <b-button
                        v-if="
                          isOnTime(
                            dateIndex,
                            event.start_time,
                            event.end_time
                          )
                        "
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        style="margin: 5px"
                        variant="outline-success"
                        :href="'/congress/'+form.slug+'/'+event.slug"
                        block
                      >
                        <span v-if="speakerEvent.some(e => e.event_id === event.id)">Entrar ao vivo</span>
                        <span v-else>Assistir</span>
                      </b-button>
                      <b-modal
                        :id="`agendar-${event.id}`"
                        title="Agendar"
                        hide-footer
                      >
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="mt-3"
                          variant="outline-primary"
                          style="margin: 3px"
                          :href="
                            URLGenerateGoogleCalendar(
                              event.title.replace(
                                /\s+/g,
                                '%20'
                              ),
                              event.date.substring(4, 0),
                              event.date.substring(7, 5),
                              event.date.substring(10, 8),
                              parseInt(
                                event.start_time.substring(2, 0)
                              ) + 3,
                              event.date.substring(4, 0),
                              event.date.substring(7, 5),
                              event.date.substring(10, 8),
                              parseInt(
                                event.end_time.substring(2, 0)
                              ) + 3,
                              event.title.replace(/\s+/g, '%20')
                            )
                          "
                          target="_blank"
                        >
                          Google agenda
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="mt-3"
                          variant="outline-primary"
                          style="margin: 3px"
                          @click="ICALGenerate(event)"
                        >
                          Ical
                        </b-button>
                        <b-button
                          v-if="userEvents.some(e => e.event_id == event.id) === false"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          class="mt-3"
                          variant="outline-primary"
                          style="margin: 3px"
                          @click="ActiveNotification(event.id)"
                        >
                          Ativar notificação
                        </b-button>
                        <b-button
                          v-else
                          class="mt-3"
                          variant="danger"
                          style="margin: 3px"
                          @click="DeactiveNotification(event.id)"
                        >
                          Desativar notificação
                        </b-button>
                        <b-button
                          class="mt-3"
                          variant="danger"
                          block
                          @click="$bvModal.hide('agendar-'+event.id)"
                        >
                          Fechar
                        </b-button>
                      </b-modal>

                      <b-modal
                        :id="'modal-'+event.id"
                        :title="event.title"
                        hide-footer
                      >
                        <div>
                          <p v-if="event.cordinator != null">
                            <b>
                              Moderador:
                            </b>
                          </p>
                          <p>
                            {{ event.cordinator }}
                          </p>
                        </div>
                        <div
                          v-for="blocks in event.blocks"
                          :key="blocks.id"
                        >
                          <p>
                            <b>
                              {{ blocks.title }}
                            </b>
                          </p>
                          <p
                            v-for="speaker in blocks.events_blocks_speaker"
                            :key="speaker.id"
                          >
                            {{ speaker.name_badge }}
                          </p>
                        </div>
                        <b-button
                          class="mt-3"
                          variant="danger"
                          block
                          @click="$bvModal.hide('modal-'+event.id)"
                        >
                          Fechar
                        </b-button>
                      </b-modal>
                    </b-card-body>
                  </b-card>
                </b-col>
              </div>
            </div>
          </b-collapse>
        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import BCardCode from '@core/components/b-card-code'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { codeAccordionDefault } from './collapse/code'
// NOTA: a função de listar os eventos pela data atual chamara o acesso do usuario
// ROTA: events/per/date/now
export default {
  components: {
    // BCardCode,
    // AppCollapse,
    // AppCollapseItem,
    BCard,
    BCardText,
    BButton,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const todaysDate = new Date()
  },
  data() {
    return {
      codeAccordionDefault,
      form: {
        slug: this.$route.params.slug
      },
      formNotification: {
        event_id: null
      },
      isLive: false,
      ordVerify: [],
      paid: null,
      loading: true,
      avatars: [
        { avatar: "require('@/assets/images/portrait/small/avatar-s-20.jpg')", fullName: 'Jenny Looper' },
      ],
    }
  },
  computed: {
    congressesActivities() {
      return this.$store.state.congress.congressCurrentActivities
    },
    userEvents() {
      return this.$store.state.congress.userEvents
    },
    order() {
      return this.$store.state.payment.order
    },
    ordersGn() {
      return this.$store.state.payment.ordersGn
    },
    user() {
      return this.$store.state.auth.sessionInfo.user
    },
    slug() {
      return this.$route.params.slug
    },
    speakerEvent() {
      return this.$store.state.congress.speakerEvents
    },
  },
  watch: {
    congressesActivities() {},
    userEvents() {},
    async ordVerify() {
      if (
        this.order.some(e => e.congress.path_event === this.slug && (e.transaction_status === 3 || e.transaction_status === 3)) ||
        this.ordersGn.some(e => e.congress.path_event === this.slug && e.transaction_status === 'paid') ||
        this.user.role === 'ADMIN' ||
        this.user.role === 'MASTER' ||
        this.user.role === 'SPONSOR' ||
        this.user.role === 'SPEAKER'
      ) {
        this.paid = true
      }
    },
    paid() {
      if (this.paid) {
        this.loading = false
      } else {
        this.$router.back()
      }
    },
    slug() {
      console.log(`mudou para${this.slug}`)
      this.ActionLoadCurrentActivities({ slug: this.slug })
    }
  },
  created() {
    this.moment = moment
    moment.locale('pt-br')
  },
  mounted() {
    this.LoadCurrentActivities().then(() => {
      this.ActionCongressAccess(this.form)
    })
    this.ActionGetEventBySpeaker()
    this.ActionGetUSerEvents()
    this.ordVerify = this.order
  },
  methods: {
    ...mapActions('congress', [
      'ActionLoadCurrentActivities',
      'ActionCongressAccess',
      'ActionRegisterUserEvent',
      'ActionUnregisterUserEvent',
      'ActionGetUSerEvents',
      'ActionGetEventBySpeaker',
    ]),
    async LoadCurrentActivities() {
      await this.ActionLoadCurrentActivities(this.form)
    },
    URLGenerateGoogleCalendar(p1, p2, p3, p4, p5, p6, p7, p8, p9, p10) {
      const url =
        `https://www.google.com/calendar/event?action=TEMPLATE&text=${
          p1
        }&dates=${
          p2
        }${p3
        }${p4
        }T${
          p5
        }0000Z/${
          p6
        }${p7
        }${p8
        }T${
          p9
        }0000Z&details=${
          p10
        }&location=`
      return url
    },
    ICALGenerate(event) {
      const now = new Date()
      const start = new Date(`${event.date} ${event.start_time}:00`)
      const end = new Date(`${event.date} ${event.end_time}:00`)
      const fileName = `Congresso - ${event.title}`
      const language = 'pt-br'
      const subject = `Congresso - ${event.title}`
      const description = `Congresso - ${event.title}`
      const location = 'site'
      const url = 'site'
      const organizer = null
      this.$ics.addEvent(language, subject, description, location, start, end, url, organizer)
      this.$ics.calendar()
      this.$ics.download(fileName)
      this.$ics.removeAllEvents()
    },
    async ActiveNotification(id) {
      this.formNotification.event_id = id
      await this.ActionRegisterUserEvent(this.formNotification)
      await this.ActionGetUSerEvents()
    },
    async DeactiveNotification(id) {
      await this.ActionUnregisterUserEvent({
        event_id: id
      })
      await this.ActionGetUSerEvents()
    },
    isOnTime(date, timeStart, timeEnd) {
      const d = date.split('-')
      const dateNow = new Date()
      let subtrac = new Date(`${d[1]}/${d[2]}/${d[0]} ${timeStart}`)
      subtrac = subtrac.setMinutes(subtrac.getMinutes() - 20)
      const dateStart = new Date(subtrac)

      if (dateNow >= dateStart) {
        this.isLive = true
        return true
      }
      return false
    }
  },
}
</script>

<style>
.btn-outline-primary:not(:disabled):not(.disabled):active, [dir] .btn-outline-primary:not(:disabled):not(.disabled).active, [dir] .btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: rgba(0, 0, 0, 0.0) !important;
}
.card-img-top{
  max-height: 100px;
}
</style>
